import classNames from 'classnames'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonWithModal from 'components/ButtonWithModal'
import Container from 'components/Container'
import Field from 'components/Field'
import FieldEmail from 'components/FieldEmail'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import Fields from 'components/Fields'
import FieldSmsConsentCheckbox from 'components/FieldSmsConsentCheckbox'
import FormValidationNotification from 'components/FormValidationNotification'
import Label from 'components/Label'
import Row from 'components/Row'
import Text from 'components/Text'
import emails from 'constants/emails'
import * as events from 'constants/events'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import ProgramChangeModal from 'features/program/components/ProgramChangeModal'
import * as logrocket from 'libs/logrocket'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'

type Props = {
  buttonsProps: {}
} & Component

export default function ProfileForm({ buttonsProps = {}, className }: Props) {
  const globalContext = useGlobalContext()
  const maskedEmail = user.maskEmail(globalContext.user.email)
  const [handleChangeProgramSubmitLoading, setHandleChangeProgramSubmitLoading] =
    React.useState(false)
  const [showProgramChangeModal, setShowProgramChangeModal] = React.useState(false)

  async function submit(values, formikActions) {
    function handleError(error) {
      console.error(error)
      const message =
        error.statusCode === statusCodes.NO_OP
          ? 'This email is already in use. Please enter a different email or call 833-701-1545 (toll-free).'
          : undefined
      notifications.notifyError(message)
      formikActions.setSubmitting(false)
    }

    const response = await userApiRequest
      .updateUser({
        ...values,
      })
      .catch((error) => {
        handleError(error)
      })

    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      handleError(response)
      formikActions.setSubmitting(false)
      return
    }

    await globalContext.update({ user: response.data })
    await globalContext.updateUser()

    // re-mask after submission of real values
    formikActions.setFieldValue('email', user.maskEmail(values.email))
    formikActions.setFieldValue('phoneNumber', '') // to just show placeholder

    formikActions.setSubmitting(false)
    notifications.notifySuccess('Profile updated successfully!')
  }

  function handleSubmit(values, formikActions) {
    function handleError(message: string) {
      notifications.notifyError(message)
      formikActions.setSubmitting(false)
    }

    if (values.smsConsent && !values.phoneNumber) {
      handleError('Oops, please enter a phone number if you wish to subscribe')
      return
    }

    const sameEmail = values.email === maskedEmail || values.email === globalContext.user.email
    const samePhone = values.phoneNumber === globalContext.user.phoneNumber

    // do nothing if no changes were made
    if (sameEmail && samePhone) {
      formikActions.setSubmitting(false)
      return
    }

    // if user edited email but it still contains an asterisk, notify them
    if (!sameEmail && _.includes(values.email, '*')) {
      handleError('Oops, please make sure your email is correct')
      return
    }

    if (sameEmail) {
      values.email = globalContext.user.email // to avoid saving it in db as the masked email
    }

    // returning the promise here is important because formik relies on this to update isSubmitting properly which we rely on to ensure we don't double-fire
    // due to https://github.com/formium/formik/issues/1730
    return submit(values, formikActions)
  }

  const currentExerciseProgram = globalContext.user.currentExerciseProgram

  async function handleChangeProgramSubmit(difficultyFeedback) {
    if (!globalContext.user) return

    setShowProgramChangeModal(true)

    function handleError() {
      globalContext.analytics?.trackEvent(events.SWITCH_USER_EXERCISE_PROGRAM, {
        success: 'false',
        reason: `previous_program_too_${difficultyFeedback}`,
        previousProgram: currentExerciseProgram,
        switchInitiatedFrom: 'settings_page',
      })
      notifications.notifyError(
        `Sorry, we couldn’t change your program right now. Please contact us at ${emails.DEFAULT}.`
      )
    }

    try {
      if (handleChangeProgramSubmitLoading) {
        // Prevent double-click: https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
        return null
      }
      setHandleChangeProgramSubmitLoading(true)
      const previousProgram = currentExerciseProgram

      const response = await userApiRequest.switchUserExerciseProgram(difficultyFeedback)

      if (response.statusCode !== 200) {
        handleError()
        setHandleChangeProgramSubmitLoading(false)
        setShowProgramChangeModal(false)
        return
      }

      globalContext.updateUser()
      notifications.notifySuccess('Program updated!')

      globalContext.analytics?.trackEvent(events.SWITCH_USER_EXERCISE_PROGRAM, {
        success: 'true',
        reason: `previous_program_too_${difficultyFeedback}`,
        previousProgram,
        newProgram: response?.data?.title,
        switchInitiatedFrom: 'settings_page',
      })
    } catch {
      handleError()
    }
    setHandleChangeProgramSubmitLoading(false)
    setShowProgramChangeModal(false)
  }

  const fieldProps = {
    ...logrocket.INPUT_PHI_PROPS,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: maskedEmail,
        smsConsent: globalContext.user.smsConsent,
      }}
      validationSchema={Yup.object({
        email: validations.EMAIL,
        phoneNumber: validations.PHONE_NUMBER_OPTIONAL,
      })}
      className={classNames('ProfileForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <Fields>
            <FieldEmail {...fieldProps} required />
            <FieldPhoneNumber
              {...fieldProps}
              placeholder={user.maskPhoneNumber(globalContext.user.phoneNumber)}
              optional
            />
          </Fields>
          <Row size="xsmall">
            <Text color="gray">Your email and phone number are hidden for your privacy</Text>
          </Row>
          <FieldSmsConsentCheckbox noReminders />
          <Row size="small">
            <Container align={buttonsProps?.align} flush size="xsmall">
              <Buttons {...buttonsProps}>
                <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                  {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Buttons>
            </Container>
          </Row>
          {!user.isFreeUser(globalContext.user) && (
            <Field>
              <Row flush={!user.canChangeProgram(globalContext.user)} size="xsmall">
                <Label>Program</Label>
                <Text>{globalContext.user.currentExerciseProgramExternalName}</Text>
              </Row>
              {user.canChangeProgram(globalContext.user) && (
                <ButtonWithModal
                  color="purple"
                  level="text"
                  showModal={showProgramChangeModal}
                  size="small"
                  modal={
                    <ProgramChangeModal
                      onSubmit={(difficultyFeedback) =>
                        handleChangeProgramSubmit(difficultyFeedback)
                      }
                    />
                  }
                  modalProps={{
                    size: 'small',
                  }}>
                  <Text size="small">Try a new program</Text>
                </ButtonWithModal>
              )}
            </Field>
          )}
        </Form>
      )}
    </Formik>
  )
}
