import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Loader from 'components/Loader'
import Row from 'components/Row'
import SignUpDisclaimer from 'components/SignUpDisclaimer'
import Text from 'components/Text'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import * as enterprise from 'libs/enterprise'
import * as user from 'libs/user'
import OnboardingEnterpriseEligible from './components/OnboardingEnterpriseEligible'
import OnboardingEnterpriseIneligible from './components/OnboardingEnterpriseIneligible'
import OnboardingEnterpriseIneligibleBecause from './components/OnboardingEnterpriseIneligibleBecause'
// import OnboardingEnterpriseIneligibleCircle from './components/OnboardingEnterpriseIneligibleCircle'
import OnboardingEnterpriseIneligibleFacebook from './components/OnboardingEnterpriseIneligibleFacebook'
import { ReactComponent as ShieldSvg } from './images/shield.svg'

export default function OnboardingEnterpriseEligibility() {
  const globalContext = useGlobalContext()
  const [isEligible, setIsEligible] = React.useState<boolean | undefined>()
  const isSpecial = user.hasHomeSpecial()

  const partnerName = globalContext.user?.enterpriseMember?.enterprise?.name
  const partnerTerms = enterprise.getTerms(
    globalContext.user?.enterpriseMember?.enterprise?.internalId
  )
  const srcId = globalContext.user?.srcId

  React.useEffect(() => {
    function checkEligibility() {
      const isElig =
        user.isEnterpriseUser(globalContext.user) && globalContext.user.enterpriseMember?.validated
      setIsEligible(isElig)
      globalContext.analytics?.markPageVisited('Onboarding enterprise eligibility', {
        isEligible: isElig ? 'true' : 'false',
      })
    }

    globalContext.update(globalContext.user, checkEligibility)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.analytics, globalContext.user])

  async function next() {
    navigate(isEligible ? paths.ONBOARDING_QUESTIONS : paths.ONBOARDING_INTRO, { replace: true })
  }

  const eligibilityProps = {
    onNext: next,
  }

  let content = null

  if (isEligible) {
    content = <OnboardingEnterpriseEligible {...eligibilityProps} />
  } else if (isSpecial) {
    content = <OnboardingEnterpriseIneligibleFacebook />
  } else if (srcId === enterprise.BECAUSE_KEY) {
    content = <OnboardingEnterpriseIneligibleBecause {...eligibilityProps} />
  } else {
    content = <OnboardingEnterpriseIneligible {...eligibilityProps} />
  }

  return (
    <OnboardingLayout
      title="Eligibility"
      redirectIfOnboardingComplete={false}
      className="OnboardingEnterpriseEligibility">
      {_.isNil(isEligible) ? (
        <Loader overlay={false} />
      ) : (
        <Container flush size="small">
          <Row size="smallx">{content}</Row>
          {!isSpecial && !isEligible && (
            <Text align="center">
              <Row size="xxsmall" aria-hidden>
                <ShieldSvg />
              </Row>
              <SignUpDisclaimer partnerName={partnerName} partnerTerms={partnerTerms} />
            </Text>
          )}
        </Container>
      )}
    </OnboardingLayout>
  )
}
