import React from 'react'
import Button from 'components/Button'
import LessonTrainer from 'components/LessonTrainer'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import * as exploreApiRequest from 'libs/explore-api-request'
import ExploreLessons from '../ExploreLessons'

export default function ExploreLessonsTrainer() {
  const [data, setData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function getData() {
      const response = await exploreApiRequest.getLessons({
        filter: {
          trainer: 'favorite',
        },
      })

      setIsLoading(false)
      setData(response.data)
    }
    getData()
  }, [])

  let lesson
  if (!isLoading && data) {
    lesson = data[0]
  }

  return (
    <ExploreLessons
      title={
        !isLoading &&
        lesson && (
          <Text color="black" element="figure">
            <MediaObject
              figure={<LessonTrainer data={lesson} showName={false} size="large" />}
              center
              size="small">
              <Row size="xxxsmall">
                <Text casing="upper" element="div" size="xsmall">
                  More classes from
                </Text>
              </Row>
              <Text element="figcaption" size="xlarge" weight="medium">
                {lesson.instructorName}
              </Text>
            </MediaObject>
          </Text>
        )
      }
      action={<Button to={`${paths.EXPLORE_LESSONS}?trainer=${lesson?.instructorName}`} />}
      data={data}
      eventKey="trainer"
      loading={isLoading}
      className="ExploreLessonsTrainer"
    />
  )
}
