import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading2 from 'components/Heading2'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import { useProgram, useProgramOverview } from 'libs/program-query'
import * as programs from 'libs/programs'
import * as user from 'libs/user'
import styles from './ProgramOverview.module.scss'
import { ReactComponent as AlarmClockSvg } from './images/alarm-clock.svg'
import { ReactComponent as ArrowsCircleSvg } from './images/arrows-circle.svg'
import { ReactComponent as BlocksSvg } from './images/blocks.svg'
import { ReactComponent as GraduationCapSvg } from './images/graduation-cap.svg'

type Props = Component

export default function ProgramOverview({ className }: Props) {
  const globalContext = useGlobalContext()
  const { data: programData } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const { data: programOverviewData } = useProgramOverview({ dependencies: [programData] })
  const programOverview = programOverviewData?.data

  const lessonDurationAverages = []
  for (let index = 0; index < _.size(programOverview?.programOverviewDetails.blockData); index++) {
    lessonDurationAverages.push(
      programOverview?.programOverviewDetails.blockData[index].averageDuration
    )
  }

  const lessonDurations = `${Math.floor(_.min(lessonDurationAverages) / 5) * 5}-${
    Math.ceil(_.max(lessonDurationAverages) / 5) * 5
  }`

  function handleChangeProgramButtonClick() {
    globalContext.analytics?.trackEvent('Program: Change Program Button: Click', {
      currentExerciseProgramId: globalContext.user?.exerciseProgramId,
    })
    navigate(paths.PROGRAM_CHANGE)
  }

  const meta = [
    {
      caption: 'sections',
      icon: BlocksSvg,
      value: _.size(program?.exerciseProgram?.exerciseBlocks),
    },
    {
      caption: 'classes total',
      icon: GraduationCapSvg,
      value: programOverview?.programOverviewDetails?.programNumClasses,
    },
    {
      caption: 'mins a day',
      icon: AlarmClockSvg,
      value: programOverview ? lessonDurations : 0,
    },
  ]

  const programFocus = globalContext.user?.latestUserProfile?.programFocus

  return (
    <Box color="white" className={classNames('ProgramOverview', className)}>
      <Row size="small" flush={user.isEnterpriseDemoClinicalUser(globalContext.user)}>
        <Heading2 level={2} levelStyle={5}>
          {programs.getProgramTitle(program.exerciseProgram)}
        </Heading2>
        <Row size="xmedium">
          <div className={styles.meta}>
            {meta
              .filter((item) => item.value)
              .map((item) => (
                <MediaObject figure={<item.icon aria-hidden />} key={item.caption} size="xxsmall">
                  <Text element="figure">
                    <Row size="xxxsmall">
                      <Text color="purple" size="xxlarge">
                        {item.value}
                      </Text>
                    </Row>
                    <Text element="figcaption" size="small" weight="medium">
                      {item.caption}
                    </Text>
                  </Text>
                </MediaObject>
              ))}
          </div>
        </Row>
        <Row size="xxsmall">
          <Text element="h3" weight="medium">
            Program description
          </Text>
        </Row>
        <Text element="p" flush>
          {program?.exerciseProgram?.description}
        </Text>
      </Row>
      {programFocus !== 'pelvic_health' && (
        <Button icon={<ArrowsCircleSvg />} level="text" onClick={handleChangeProgramButtonClick}>
          Change program
        </Button>
      )}
    </Box>
  )
}
