import classNames from 'classnames'
import React from 'react'
import ButtonWithModal from 'components/ButtonWithModal'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as auth from 'libs/auth'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'

type Props = {
  children?: React.ReactNode
  onClick?: () => void
} & Component

export default function AccountDeleteButton({
  children = 'Delete my Bold account',
  className,
  onClick,
  ...props
}: Props) {
  const globalContext = useGlobalContext()

  async function deleteAccount() {
    const response = await userApiRequest.deleteUser()

    if (response.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Something went wrong. Please try again later.')

      return
    }

    globalContext.analytics?.trackEvent('Account deleted')

    auth.signOut({})

    notifications.notifyAndRedirect({
      message: 'Successfully deleted account',
      to: paths.HOME,
    })
  }

  function handleConfirm() {
    deleteAccount()
  }

  return (
    <ButtonWithModal
      {...props}
      modalProps={{
        cancelText: 'No',
        confirmButtonProps: {
          children: 'Yes, delete',
          color: 'red',
        },
        size: 'xsmall',
        theme: 'danger',
        title: 'Are you sure you want to delete your Bold account?',
        onConfirm: handleConfirm,
      }}
      modalType="confirm"
      className={classNames('AccountDeleteButton', className)}>
      {children}
    </ButtonWithModal>
  )
}
