import React from 'react'
import ExploreLessonsWithoutFilters from 'features/explore/components/ExploreLessonsWithoutFilters'
import LessonBox from 'features/lesson/components/LessonBox'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as lessonsQuery from 'libs/lessons-query'

export default function ExploreBoldTalks() {
  useEventPageVisited('Bold talks')
  const { data, isLoading } = lessonsQuery.useLessonsBoldTalks()

  return (
    <ExploreLessonsWithoutFilters
      title="Bold Talks"
      loading={isLoading}
      className="ExploreBoldTalks">
      {data.data.map((item) => (
        <LessonBox data={item} key={item.id} />
      ))}
    </ExploreLessonsWithoutFilters>
  )
}
