import React from 'react'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Fieldset from 'components/Fieldset'
import Head from 'components/Head'
import Header from 'components/Header'
import HeaderSubheader from 'components/HeaderSubheader'
import Link from 'components/Link'
import PasswordChangeForm from 'components/PasswordChangeForm'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseSubscription from 'features/enterprise/components/EnterpriseSubscription'
import AccountDeleteButton from 'features/user/components/AccountDeleteButton'
import ProfileForm from 'features/user/components/ProfileForm'
import Subscription from 'features/user/components/Subscription'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as url from 'libs/url'
import * as user from 'libs/user'

export default function Settings() {
  useEventPageVisited(events.SETTINGS_PAGE)
  const globalContext = useGlobalContext()
  const { showDelete } = url.getQueryString()

  const passwordId = 'password'
  const planId = 'plan'
  const profileId = 'profile'

  const navs = [
    {
      copy: 'Profile',
      to: `#${profileId}`,
    },
    {
      copy: 'Plan',
      to: `#${planId}`,
    },
    {
      copy: 'Password',
      to: `#${passwordId}`,
    },
  ]

  const buttonsProps = {
    align: 'right',
  }

  const subscriptionProps = {
    title: 'Plan',
  }

  return (
    <div className="Settings">
      <Head
        bodyAttributes={{
          class: 'body---grey',
        }}
      />
      <Header
        subheader={
          <HeaderSubheader>
            <Buttons align="center">
              {navs.map((item) => (
                <Link to={item.to} key={item.to}>
                  <Text weight="medium">{item.copy}</Text>
                </Link>
              ))}
            </Buttons>
          </HeaderSubheader>
        }
      />
      <View color="grey">
        <Container flush size="medium">
          <Row>
            <Fieldset title="Profile" id={profileId}>
              <ProfileForm buttonsProps={buttonsProps} />
            </Fieldset>
            <Subscription {...subscriptionProps} id={planId} />
            <EnterpriseSubscription {...subscriptionProps} id={planId} />
            {user.hasPassword(globalContext.user) && (
              <Fieldset title="Password" id={passwordId}>
                <PasswordChangeForm
                  buttonProps={{ children: 'Save' }}
                  buttonsProps={buttonsProps}
                />
              </Fieldset>
            )}
          </Row>
          <Buttons align="center">
            <AccountDeleteButton showModal={showDelete} level="text" size="small" />
          </Buttons>
        </Container>
      </View>
    </div>
  )
}
