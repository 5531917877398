import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import * as lessonsQuery from 'libs/lessons-query'
import ExploreLessons from '../ExploreLessons'

export default function ExploreLessonsFavorite() {
  const { data, isLoading } = lessonsQuery.useUserLessonsFavorite()

  return (
    <ExploreLessons
      title="My saved classes"
      action={<Button to={paths.LESSONS_FAVORITE}>See all saved classes</Button>}
      data={data?.data}
      eventKey="favorites"
      loading={isLoading}
      className="ExploreLessonsFavorite"
    />
  )
}
