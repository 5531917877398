import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import * as exploreApiRequest from 'libs/explore-api-request'
import ExploreLessons from '../ExploreLessons'

export default function ExploreLiveLessons() {
  const [data, setData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function getData() {
      const response = await exploreApiRequest.getLessons({
        isLiveClass: 'true',
      })

      setIsLoading(false)
      setData(response.data)
    }
    getData()
  }, [])

  return (
    <ExploreLessons
      title="Missed a live class? Watch the recording"
      action={<Button to={`${paths.EXPLORE_LESSONS}?preset=isLiveClass`} />}
      data={data}
      eventKey="live classes"
      loading={isLoading}
      className="ExploreLiveLessons"
    />
  )
}
