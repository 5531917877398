import { navigate } from 'gatsby'
import React from 'react'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import type { Lesson } from 'libs/lesson'
import * as navigation from 'libs/navigation'
import { useProgram, useProgramCurrentWeek, useUserProgramBlocks } from 'libs/program-query'
import ProgramBlocks from './components/ProgramBlocks'
import ProgramHeader from './components/ProgramHeader'
import ProgramOverview from './components/ProgramOverview'
import ProgramProgressionModals from './components/ProgramProgressionModals'
import ProgramLayout from '../ProgramLayout'

export default function ProgramDynamic() {
  useEventPageVisited('DynamicProgram')
  const { data: programData, mutate: mutateProgram } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const { mutate: mutateProgramCurrentWeek } = useProgramCurrentWeek()
  const { mutate: mutateUserProgramBlocks } = useUserProgramBlocks(program?.id, {
    dependencies: [programData],
  })
  const [lessonOverride, setLessonOverride] = React.useState<Lesson | undefined>()
  const [onSkip, setOnSkip] = React.useState({ skipped: false })
  const [playSkipped, setPlaySkipped] = React.useState({ skipped: false })

  React.useEffect(() => {
    if (programData?.statusCode === 422) {
      navigate(paths.PROGRAM_BUILD, { replace: true })
    }
    mutateProgram()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programData])

  function handleLessonOverride() {
    setLessonOverride(null)
  }

  function handleLessonClick(lesson: any) {
    setLessonOverride(lesson)
    navigation.scrollToTop()
  }

  const handleLessonSkipped = async () => {
    setOnSkip({ skipped: true })
    await mutateUserProgramBlocks()
  }

  const handlePlaySkippedLesson = () => {
    setPlaySkipped({ skipped: true })
  }

  async function handleVideoEnd() {
    setLessonOverride(null)
    await mutateProgram()
    await mutateUserProgramBlocks()
    await mutateProgramCurrentWeek()
  }

  function handleChangeProgramClose() {
    // nothing to do currently
  }

  return (
    <ProgramLayout
      header={
        <ProgramHeader
          lessonOverride={lessonOverride}
          // onLessonClick={handleLessonClick}
          onLessonOverride={handleLessonOverride}
          onLessonSkipped={handleLessonSkipped}
          onPlaySkippedLesson={handlePlaySkippedLesson}
          onVideoEnd={handleVideoEnd}
        />
      }
      overview={<ProgramOverview />}
      blocks={
        <ProgramBlocks
          lessonOverride={lessonOverride}
          playSkippedLesson={playSkipped}
          onLessonClick={handleLessonClick}
          onLessonSkipped={onSkip}
        />
      }
      loading={!program}
      className="ProgramDynamic">
      <ProgramProgressionModals onClose={handleChangeProgramClose} />
    </ProgramLayout>
  )
}
