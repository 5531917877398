import React from 'react'
import * as exploreApiRequest from 'libs/explore-api-request'
import ExploreLessons from '../ExploreLessons'

export default function ExploreLessonsRecommended() {
  const [data, setData] = React.useState([{}])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function getData() {
      const response = await exploreApiRequest.getLessons({
        source: 'dashboard',
      })

      if (response.statusCode !== 200) return

      setIsLoading(false)
      setData(response.data)
    }
    getData()
  }, [])

  return (
    <ExploreLessons
      title="Recommended for you"
      data={data}
      loading={isLoading}
      className="ExploreLessonsRecommended"
    />
  )
}
