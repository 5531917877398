import _ from 'lodash'
import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Box from 'components/Box'
import Button from 'components/Button'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Challenge as ChallengeType } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import constants from 'styles/constants.module.scss'
import { ReactComponent as BadgeSvg } from './images/badge.svg'

export default function ExploreChallenge() {
  const globalContext = useGlobalContext()
  const { width } = useWindowSize()
  const [challenge, setChallenge] = React.useState<ChallengeType | {}>({})
  const [isChallengeActive, setIsChallengeActive] = React.useState(false)
  const [isJoiningChallenge, setIsJoiningChallenge] = React.useState(false)

  const getData = React.useCallback(async () => {
    if (user.isEnterpriseOctopusUser(globalContext.user)) return

    const challengeType = 'oneoff'

    const challengesResponse = await challengeApiRequest.getActiveChallenges({
      type: challengeType,
    })

    const challengesActiveResponse = await challengeApiRequest.getUserChallenges({
      status: 'active',
      isChallengeActive: true,
      type: challengeType,
    })

    const challengesData = challengesResponse?.data
    const challengeData = _.orderBy(challengesData, 'activatedAt', 'asc')[0]

    const challengeActiveData = challengesActiveResponse?.data.find(
      (activeChallenge) => activeChallenge.challengeId === challengeData?.id
    )

    setChallenge(challengeData)
    setIsChallengeActive(!!challengeActiveData)
  }, [globalContext.user])

  React.useEffect(() => {
    getData()
  }, [getData])

  // TODO: dedupe with features/user/pages/Activity/components/Challenges
  async function handleJoinChallenge(challengeId, challengeName) {
    try {
      // Prevent double click
      // https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
      if (isJoiningChallenge) {
        return null
      }

      setIsJoiningChallenge(true)
      await challengeApiRequest.joinChallenge(challengeId)

      //setIsChallengeActive(true)
      getData()
      setIsJoiningChallenge(false)
      notifications.notifySuccess('Thanks for joining the challenge!')
      globalContext.analytics?.trackEvent(events.DASHBOARD_CHALLENGE_JOIN_BUTTON_CLICK, {
        challengeId,
        challengeName,
        success: true,
      })
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: true,
      })
    } catch {
      setIsJoiningChallenge(false)
      notifications.notifyError(
        'Something went wrong joining that challenge. Please try again later.'
      )
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: false,
      })
    }
  }

  if (user.isEnterpriseOctopusUser(globalContext.user) || _.isEmpty(challenge)) {
    return null
  }

  return (
    <Box color="pink" className="ExploreChallenge">
      <MediaObject figure={<BadgeSvg aria-hidden />} center reset="tablet" size="small">
        <MediaObject
          figure={
            isChallengeActive ? (
              <Button to={paths.getChallengePath(challenge)}>View classes</Button>
            ) : (
              <Button
                loading={isJoiningChallenge}
                onClick={() => handleJoinChallenge(challenge.id, challenge.name)}>
                Join challenge
              </Button>
            )
          }
          center
          figurePosition="right"
          reset="tablet">
          <Text
            align={width <= Number(constants.TABLET_PX) ? 'center' : undefined}
            color="black"
            element="div">
            {!isChallengeActive && (
              <Row size="xxsmall">
                <Text casing="upper" color="black" element="h2" size="xsmall" weight="medium">
                  Join the challenge
                </Text>
              </Row>
            )}
            <Row size="xxsmall">
              <Text element="h3" size="xlarge" weight="medium">
                {challenge.name}
              </Text>
            </Row>
            <Text element="p" flush lines={2}>
              {challenge.description}
            </Text>
          </Text>
        </MediaObject>
      </MediaObject>
    </Box>
  )
}
