import classNames from 'classnames'
import { Form, Formik } from 'formik'
import Handlebars from 'handlebars'
import HtmlParser from 'html-react-parser'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Box from 'components/Box'
import Button, { type ButtonProps } from 'components/Button'
import ButtonsFloating from 'components/ButtonsFloating'
import Field from 'components/Field'
import Field2 from 'components/Field2'
import FieldCheckboxButtons from 'components/FieldCheckboxButtons'
import FieldInputRange from 'components/FieldInputRange'
import FieldNumber from 'components/FieldNumber'
import FieldRadioButtons from 'components/FieldRadioButtons'
import FieldSlider from 'components/FieldSlider'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import * as validations from 'libs/validations'
import * as checkins from 'libs/checkins/checkin-constants'
import './CheckinSingleForm.scss'
import { ReactComponent as ArrowLeftSvg } from './images/arrow---left.svg'

type Props = {
  buttonProps?: ButtonProps
  checkinTracker: unknown
  onResponse?: () => void
  onSuccess: () => void
  showCount?: boolean
  size?: 'medium' | 'small'
} & Component

export default function CheckinSingleForm({
  buttonProps: propsButtonProps = {
    children: 'Continue',
  },
  checkinTracker,
  className,
  onResponse,
  onSuccess,
  showCount,
  size = 'medium',
}: Props) {
  const [currentQuestion, setCurrentQuestion] = React.useState(null)
  const [hasRageClicked, setHasRageClicked] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(true)

  React.useEffect(() => {
    if (_.isEmpty(checkinTracker)) return
    setCurrentQuestion(checkinTracker.currentNode)
  }, [checkinTracker])

  const handleRageClick = React.useCallback(() => {
    setHasRageClicked(true)
  }, [])

  React.useEffect(() => {
    setHasRageClicked(false)
    const buttonContainer = document.getElementById('buttonContainer')
    if (!buttonContainer) return
    if (isDisabled) {
      buttonContainer.addEventListener('click', handleRageClick)
    } else {
      buttonContainer.removeEventListener('click', handleRageClick)
    }
  }, [currentQuestion, isDisabled, handleRageClick])

  function updateCurrentQuestion() {
    setCurrentQuestion(checkinTracker.currentNode)
  }

  function handleBack() {
    checkinTracker.handleMoveBack()
    updateCurrentQuestion()
  }

  async function submit(promptResponse = null) {
    if (onResponse) onResponse()
    // awaiting makes this slower...one day...one day...
    await checkinTracker.handleRespond(promptResponse)
    if (checkinTracker.isCheckinPopulated()) {
      await checkinTracker.markCheckinAsComplete()
      onSuccess()
      return
    }

    updateCurrentQuestion()
  }

  async function handleSubmit(values) {
    setIsDisabled(true)
    const key = currentQuestion.code
    // TODO: cleanup in CheckinForm as well
    //const prompt = checkinTracker.rootNodes.find((item) => item.code === key)
    const prompt = checkinTracker.currentNode
    const value = values[key]
    let promptResponse

    if (currentQuestion.type === checkins.PROMPT_TYPE_TEXT) {
      prompt.responses[0].text = value
      prompt.responses[0].value = checkins.PROMPT_TYPE_TEXT_RESPONSE_VALUE
      promptResponse = prompt.responses[0]
    } else if (currentQuestion.type === checkins.PROMPT_TYPE_NUMBER) {
      prompt.responses[0].text = value
      prompt.responses[0].value = value
      promptResponse = prompt.responses[0]
    } else if (currentQuestion.type === 'multipleSelectMultipleChoice') {
      prompt.responses.every((response) => {
        if (_.includes(value, response.value)) {
          const multipleSelectResponse = {
            ...response,
            value,
          }
          promptResponse = multipleSelectResponse
          return false
        }
        return true
      })
    } else if (currentQuestion.type === checkins.PROMPT_TYPE_RANGE_CONTINUOUS) {
      promptResponse = {
        ...prompt.responses[0],
        value,
      }
    } else {
      promptResponse = prompt.responses.find((item) => item.value === value)
    }
    return await submit(promptResponse)
  }

  if (!currentQuestion) {
    return <Loader overlay={false} />
  }

  const previousStatementQuestionCount = _.size(
    checkinTracker?.history.filter((node) => node.type === 'statement')
  )

  const buttonProps = {
    ...propsButtonProps,
    full: true,
  }

  const fieldProps = {
    key: currentQuestion.code,
    label: HtmlParser(Handlebars.compile(currentQuestion.text)(checkinTracker?.metadata)),
    labelNote: currentQuestion.subText
      ? HtmlParser(Handlebars.compile(currentQuestion.subText)(checkinTracker?.metadata))
      : '',
    name: currentQuestion.code,
    required: currentQuestion.required,
  }

  const buildValidationObject = (question) => {
    // statements shouldn't be required
    let value =
      question.required && question.type !== 'statement' ? validations.REQUIRED : undefined
    switch (question.type) {
      case 'numberInput': {
        value = question.required ? validations.NUMBER_REQUIRED : validations.NUMBER
        break
      }
      case 'multipleSelectMultipleChoice': {
        value =
          question.required && !checkinTracker.checkinCode.includes('default_onboarding')
            ? validations.CHECKBOXES
            : undefined
        break
      }
      default: {
        break
      }
    }
    return {
      [question.code]: value,
    }
  }

  let initialValue = ''

  if (currentQuestion.type === 'freeTextInput') {
    initialValue = currentQuestion.responses[0].text
  } else if (currentQuestion.type === 'multipleSelectMultipleChoice') {
    const markedResponses = _.filter(currentQuestion.responses, (response) => response.selected)
    initialValue = markedResponses.map((response) => response.value)
  } else if (currentQuestion.status === 'complete') {
    initialValue = currentQuestion.responses.find((item) => item.selected)?.value ?? ''
  } else if (currentQuestion.type === 'slider') {
    const optionsCount = _.size(currentQuestion.responses)
    // is even
    const optionIndex = optionsCount % 2 === 0 ? optionsCount / 2 : Math.floor(optionsCount / 2)
    initialValue = currentQuestion.responses[optionIndex].value
  } else if (currentQuestion.type === checkins.PROMPT_TYPE_RANGE_CONTINUOUS) {
    initialValue =
      currentQuestion.responses.find((response) => response.selected === true)?.value ?? 0
  }

  return (
    <div className={classNames('CheckinSingleForm', `CheckinSingleForm---${size}`, className)}>
      <Row element="header" size="small" className="CheckinSingleForm--header">
        {(currentQuestion.hasParent() || checkinTracker?.rootIndex > 0) && (
          <Button
            color="black"
            level="text"
            className="CheckinSingleForm--back-button"
            onClick={handleBack}
            aria-label="Go back to previous question">
            <ArrowLeftSvg aria-hidden />
          </Button>
        )}
        {showCount && currentQuestion.type !== 'statement' && (
          <Tag color="blue">
            Question {_.size(checkinTracker.history) + 1 - previousStatementQuestionCount}
          </Tag>
        )}
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          [currentQuestion.code]: initialValue,
        }}
        validationSchema={Yup.object(buildValidationObject(currentQuestion))}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form className="CheckinSingleForm--form">
            {currentQuestion.code === 'able_to_perform' && (
              <Row size="small">
                <Text align="left" element="p" flush weight="semibold">
                  Let us know how the class went:
                </Text>
              </Row>
            )}

            {currentQuestion.type === 'freeTextInput' && (
              <>
                <Field2
                  {...fieldProps}
                  placeholder={Handlebars.compile(currentQuestion.responses[0].placeholder)(
                    checkinTracker.metadata
                  )}
                  type="textarea"
                />
                <Button
                  {...buttonProps}
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}
                />
              </>
            )}

            {currentQuestion.type === 'numberInput' && (
              <>
                <FieldNumber {...fieldProps} />
                <Button
                  {...buttonProps}
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}
                />
              </>
            )}

            {currentQuestion.type === 'singleSelectMultipleChoice' && (
              <FieldRadioButtons
                {...fieldProps}
                axis={
                  currentQuestion.responses[0].subText && _.last(currentQuestion.responses).subText
                    ? 'x'
                    : undefined
                }
                disabled={formikProps.isSubmitting}
                flush
                minLabel={currentQuestion.responses[0].subText}
                maxLabel={_.last(currentQuestion.responses).subText}
                options={currentQuestion.responses}
                size={(currentQuestion.responses.length > 8 ? 'small' : size) || 'medium'}
                onChange={() => {
                  _.defer(formikProps.submitForm)
                }}
              />
            )}

            {currentQuestion.type === 'multipleSelectMultipleChoice' &&
              currentQuestion.code !== 'movements_challenging' && (
                <>
                  <FieldCheckboxButtons
                    {...fieldProps}
                    disabled={formikProps.isSubmitting}
                    options={currentQuestion.responses}
                    size={size || 'medium'}
                  />
                  <Button
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.submitForm}
                    color="yellow">
                    Continue
                  </Button>
                </>
              )}

            {currentQuestion.type === 'slider' && (
              <>
                <FieldSlider
                  {...fieldProps}
                  options={currentQuestion.responses}
                  onChange={() => setIsDisabled(false)}
                />
                <Button
                  {...buttonProps}
                  disabled={isDisabled ?? formikProps.isSubmitting}
                  onClick={formikProps.submitForm}
                />
              </>
            )}

            {currentQuestion.type === 'slider_continuous' && (
              <>
                <Row flush={!isDisabled} size="xmedium">
                  <FieldInputRange
                    {...fieldProps}
                    options={currentQuestion.responses}
                    onChange={() => setIsDisabled(false)}
                  />
                  {hasRageClicked && (
                    <Text element="p" flush>
                      Please select a new score before continuing.
                    </Text>
                  )}
                </Row>
                <div id="buttonContainer">
                  <Button
                    {...buttonProps}
                    disabled={isDisabled ?? formikProps.isSubmitting}
                    onClick={formikProps.submitForm}
                  />
                </div>
              </>
            )}

            {currentQuestion.type === 'statement' && (
              <>
                <Field>
                  <Box className="CheckinSingleForm--statement">
                    <Text element="p" flush={!currentQuestion.subText} size="large">
                      {HtmlParser(
                        Handlebars.compile(currentQuestion.text)(checkinTracker.metadata)
                      )}
                    </Text>
                    {currentQuestion.subText && (
                      <Text element="p" flush>
                        {HtmlParser(
                          Handlebars.compile(currentQuestion.subText)(checkinTracker.metadata)
                        )}
                      </Text>
                    )}
                  </Box>
                </Field>
                <Button
                  {...buttonProps}
                  color="yellow"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}>
                  Continue
                </Button>
              </>
            )}

            {currentQuestion.code === 'movements_challenging' && (
              <>
                <FieldCheckboxButtons
                  {...fieldProps}
                  disabled={formikProps.isSubmitting}
                  options={currentQuestion.responses}
                  size={size || 'medium'}
                />
                <ButtonsFloating>
                  <Button
                    color="yellow"
                    disabled={formikProps.isSubmitting}
                    full
                    size="large"
                    onClick={formikProps.submitForm}>
                    Next
                  </Button>
                </ButtonsFloating>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
