import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import DateTime from 'components/DateTime'
import Heading2 from 'components/Heading2'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import LiveLessonSignUpButton from 'features/live-lesson/components/LiveLessonSignUpButton'
import * as date from 'libs/date'
import type { LessonStream } from 'libs/lesson'
import * as liveLesson from 'libs/live-lesson'
import styles from './LiveLessons.module.scss'
import { ReactComponent as CalendarSvg } from './images/calendar.svg'
import { ReactComponent as LiveLessonSvg } from './images/live-lesson.svg'
import Box from '../Box'

type Props = {
  data: LessonStream[]
} & Component

export default function LiveLessons({ className, data }: Props) {
  const globalContext = useGlobalContext()

  function handleViewAllButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_LIVE_LESSONS_VIEW_ALL_CLICK)
  }

  const upcomingLiveLessons = liveLesson.sortAndFilterUpcomingLiveLessons(data)

  if (_.isEmpty(upcomingLiveLessons)) return null

  const lessonStream = upcomingLiveLessons[0]
  const { lesson } = lessonStream

  if (_.isEmpty(lesson)) return null

  const lessonsScheduled = upcomingLiveLessons.filter(
    (item: LessonStream) =>
      item.scheduledLessons?.id && item.scheduledLessons?.status !== 'canceled'
  )
  const lessonScheduled = lessonsScheduled[0]
  const lessonScheduledBreakpoint = 600
  const haslessonScheduled = !_.isEmpty(lessonScheduled)
  const rsvpCount = _.size(lessonsScheduled)

  return (
    <Box
      title="Live classes"
      titleColor="blue"
      titleIcon={<LiveLessonSvg />}
      headerButtonEntity="live schedule"
      headerButtonTo={paths.LIVE_LESSONS}
      onHeaderButtonClick={handleViewAllButtonClick}
      image={lesson.videoThumbnail}
      footer={
        haslessonScheduled || rsvpCount ? (
          <Text element="div" weight="medium">
            <Text align="center" color="blue" weight="semibold" className={styles.rsvps}>
              <span>{rsvpCount}</span>
            </Text>
            {/* pluralize doesn't handle acronym capitalization */}
            RSVP{rsvpCount > 1 && 's'}
            {
              // and condition was bugging…
              rsvpCount && haslessonScheduled ? (
                <Media query={{ minWidth: lessonScheduledBreakpoint }}>
                  <>{' • '}</>
                </Media>
              ) : undefined
            }
            {haslessonScheduled && (
              <Media query={{ minWidth: lessonScheduledBreakpoint }}>
                <>Next class at {date.format(lessonScheduled.scheduledStartTs, 'h:mmaaa eeee')}</>
              </Media>
            )}
          </Text>
        ) : undefined
      }
      className={classNames('LiveLessons', styles.this, className)}>
      <Row size="xxxsmall">
        <Heading2 flush level={3} levelStyle={5}>
          {lesson.videoTitle}
        </Heading2>
      </Row>
      <Row size="xxsmall">
        <DateTime
          data={lessonStream.scheduledStartTs}
          format="displayShortWithTime"
          icon={<CalendarSvg />}
          weight="medium"
        />
      </Row>
      <Text element="p" lines={3}>
        {lesson.instructions}
      </Text>
      <LiveLessonSignUpButton
        color="purple"
        data={lessonStream}
        className={styles.button}
        onClick={() => {
          globalContext.analytics?.trackEvent(events.DASHBOARD_LIVE_LESSONS_SIGN_UP_CLICK, {
            click_source: 'Dashboard',
            duration: lesson.duration,
            title: lesson.videoTitle,
            trainer: lesson.instructorName,
          })
        }}>
        RSVP
      </LiveLessonSignUpButton>
    </Box>
  )
}
